<template>
  <div>
    <div class="m-art-wrap">
        <div class="m-art-list" v-for="i in list" :key="i.id">
          <div class="list-info">
            <div class="lis-img">
              <img v-lazy="i.photo" />
            </div>
            <div class="lis-desc">
              <div class="des-tit f-toe" @click="businessInfo(i.id)">{{ i.title }}</div>
              <div class="des-time">
                <span class="lable">日期：</span
                ><span class="pcdate">{{ i.generate_time }}</span><span class="mbdate">{{ i.generate_time.match(/^([^\s]+)(?=\s)/)[0] }}</span>
              </div>
              <div class="des-txt">{{ i.name }}</div>
            </div>
          </div>
        </div>
    </div>

   
  </div>
</template>

<script>

export default {
  components: {
  },
  name: "articList",
  props: {
    pageSize: {
      type: Number,
      value: 10,
    },
    pageSizes: {
      type: Array,
      default: () => [10, 20, 50, 100],
    },
    list: Array,
    isShow: Boolean,
  },
  data() {
    return {
      currpage: 1, //默认当前页
      totalpage: 1000, //总页数
    };
  },
  methods: {
    // 详情跳转
    businessInfo(id) {
      this.$router.push({
        path: "/articleDetails",
        query: { id: id },
      });
    },
    //pageing 当前页改变
    handlepage(page) {
      this.currpage = page;
      this.upArraydata();
    },
  },
};
</script>
<style lang="less">
.cir-pag {
  margin: 43px 0 0;
}

.m-art-wrap {
  background-color: white;
  padding: 0 25px;
  box-sizing: border-box;
}
.m-art-list {
  padding:25px 0;
  &+.m-art-list{
    border-top: 1px solid #e5e5e5;
  }
  .list-info {
    display: flex;
    justify-content: start;
    // align-items: center;
    
    .lis-img {
      flex: 0 0 240px;
      width: 240px;
      height: 180px;
      overflow: hidden;
      margin-right: 25px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .lis-desc {
      flex: auto;
      overflow: hidden;
      .des-tit {
        font-size: 22px;
        line-height: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;

        color: #333333;
        cursor: pointer;
        &:hover {
          color: #f02420;
          text-decoration: underline;
        }
      }
      .des-time {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #b0b0b0;
        margin-top: 23px;
        margin-bottom: 23px;
        .mbdate{
            display: none;
        }
      }
      .des-txt {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 24px;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .m-art-wrap {
    padding: 0 15px;
    box-sizing: border-box;
  }
  .m-art-list {
    padding:15px 0;
    box-sizing: border-box;
    .list-info {
      .lis-img {
        flex: 0 0 (240px / 2);
        width: (240px / 2);
        height: (180px / 2);
        margin-right: 15px;
      }
      .lis-desc {
        .des-tit {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #222222;
          line-height: 21px;
        }
        .des-time{
          font-size: 11px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #959595;
          margin-top: 15px;
          margin-bottom: 0;
        }
        .lable{
            display: none;
        }
        .des-txt{
            display: none;
        }
        .pcdate{
            display: none;
        }
        .mbdate{
            display: block !important;
        }
      }
    }
  }
}
</style>
