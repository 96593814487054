<!-- 首页 f-wrap-->
<template>
  <div class="home">
   
    <div
      class="main"
      v-for="(item, index) in homeList"
      :key="item"
      :style="index % 2 === 0 ? 'background: #fff;' : 'background-color: #F1F1F1;'"
    >
      <div class="f-wrap">
        <img v-lazy="item" />
      </div>
    </div>
    <div
      class="mbmain"
      v-for="(item, index) in mbhomeList"
      :key="item"
      :style="index % 2 === 0 ? 'background: #fff;' : 'background-color: #F1F1F1;'"
    >
      <div class="f-wrap">
        <img v-lazy="item" />
      </div>
    </div>
    <!-- 帮助中心 安贸通没有-->
    <!--
    <div class="cnet f-wrap">
      <div class="cnet-info">
        <div class="info-title">帮助中心</div>
        <div class="more">
          <router-link style="font-size: 16px" :to="'/helpCenter'"
            >更多>></router-link
          >
        </div>
      </div>
      <div class="cnet-info2">
        <div class="info-title2"><span>帮助</span>中心</div>
        <div class="more2">
          <router-link style="font-size: 16px" :to="'/helpCenter'"
            ><img src="../assets/mobile/home/more@2x.png"
          /></router-link>
        </div>
      </div>
      <ArticList :list="helpList" :isShow="false" />
    </div>
    -->
  </div>
</template>

<script>
import { indexApi } from "@/api/module.js";
import ArticList from "../components/articList";
import { Swiper, SwiperSlide,directive  } from 'vue-awesome-swiper'
// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css'
export default {
  components: { ArticList,Swiper,SwiperSlide},
  directives: {
    swiper: directive
  },
  name: "home",
  data() {
    return {
      homeList: [
        require("@/assets/pc/home/p01_2.png"),
        require("@/assets/pc/home/p02.png"),
        require("@/assets/pc/home/p03.png"),
        require("@/assets/pc/home/p04.png"),
        require("@/assets/pc/home/p05_.png"),
      ],
      mbhomeList: [
        require("@/assets/mobile/home/p01_2.png"),
        require("@/assets/mobile/home/p02.png"),
        require("@/assets/mobile/home/p03.png"),
        require("@/assets/mobile/home/p04.png"),
        require("@/assets/mobile/home/p05_.png"),
      ],
      helpList: [], //帮助中心列表
      bannerArray:[],
      //滑动配置[obj]
        sliderinit: {
          currentPage: 0,//当前页码
          thresholdDistance: 500,//滑动判定距离
          thresholdTime: 100,//滑动判定时间
          autoplay:1000,//自动滚动[ms]
          loop:true,//循环滚动
          infinite:1,//无限滚动前后遍历数
          slidesToScroll:1,//每次滑动项数
        },
        swiperOption: {
          pagination: {
            el: '.swiper-pagination'
          },
          autoplay: true,
          // ...
        }
    }
  },
  props: {
    msg: String,
  },
  created(){
    
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    this.requestGetChaosList();
    //获取bannner
    indexApi.getBanner({}).then((res) => {
        if (res.data.data && res.data.data.length > 0) {
          this.bannerArray = res.data.data
        }
    });
  },
  methods: {
    requestGetChaosList() {
      const self = this;
      self.GoodsClassList = [];
      indexApi.getChaosListFn({ page: 1, size: 5, class_id: 7 }).then((res) => {
        if (res.data.data && res.data.data.length > 0) {
          self.helpList = res.data.data;
        }
      });
    },
  },
};
</script>
<style>
.el-carousel__container{
    height: 600px;
  }
</style>
<style lang="less" scoped>
@import "~@/style/index.less";
.home {
  
  .header-img {
    width: 100%;
    // max-height: 600px;
    height: auto;
    overflow: hidden;
    .swiper-container {

    height: 100%;
    .swiper-slide{
      width: 100%;
      height: 100%;
    }
    /deep/ .swiper-pagination-bullet-active{
      background: white !important;
    }
}
    
    img {
      width: 100%;
      height: 100%;
    }
  }
  .mbmain,
  .cnet-info2 {
    display: none;
  }
  .main {
    display: block;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .cnet {
    margin: 30px auto 0;
    .cnet-info {
      height: 44px;
      line-height: 44px;
      font-weight: 400;
      font-family: Microsoft YaHei;
      border-bottom: 2px solid #f02420;
      margin-bottom: 30px;
      .info-title {
        background: #f02420;
        font-size: 22px;
        color: #f1f1f1;
        text-align: center;
        display: inline-block;
        padding: 0px 20px;
      }
      .more {
        float: right;
        cursor: pointer;
        a {
          color: #666666;
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .home {
    .header-img {
      width: 100vw;
      height: calc(100vw / 2);
      img {
        width: 100%;
        height: 100%;
      }
    }
    .cnet{
        margin: 0;
    }
    .main,
    .cnet-info {
      display: none;
    }
    .mbmain {
      display: block;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .cnet-info2 {
       padding: (30px / 2);
      .felx_space();

      img {
        width: (78px / 2);
        height: (24px / 2);
      }
      .info-title2 {
        color: #222222;
        font-size: (32px / 2);
        font-family: PingFang SC;
        font-weight: bold;
        span {
          color: #f02420;
        }
      }
    }
  }
}
</style>
